body {
    font-family: 'Lato';
    letter-spacing: 1px;
}

.login .panel-default > .panel-heading {
    color: #ffffff !important;
    background-color: #00b3ee !important;
    border-color: #00b3ee !important;
}

.login .panel-default {
    border-color: #00b3ee !important;
}

.login .btn-primary:hover, .login .btn-primary {
    background-color: #00b3ee !important;
    border-color: #00b3ee !important;
}

.fa-btn {
    margin-right: 6px;
}

.question_padding {
    padding: 10px 0 10px 30px;
    margin: 10px 0 0 20px;
}

.margin_top_10 {
    margin-top: 10px;
}

#owl-demo .item img {
    display: block;
    width: 100%;
    height: auto;
}

#plugin_page section {
    padding-bottom: 20px;
}

.padding_top_20 {
    padding-top: 20px;
}

.icon-outline {
    position: relative;
    -webkit-transition: box-shadow .2s;
    transition: box-shadow .2s;
    background: #109381;
    top: -7px;
    color: #fff;
    padding: 3px;
    font-size: 11px;
}

.outline-notification {
    display: inline-block;
}

.cd-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 60px;
    right: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    /* image replacement properties */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: rgba(2, 168, 243, 0.8) url(../../images/arrow.svg) no-repeat center 50%;
    visibility: hidden;
    border-radius: 40px;
    opacity: 0;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
    /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.no-touch .cd-top:hover {
    background-color: #00b3ee;
    opacity: 1;
}

.faq_container li {
    padding: 10px;
}

.faqs_container {
    min-height: 600px;
}

.static_header .navbar-default {
    background-color: transparent !important;
    border: none !important;
}


.static_header .nav.navbar-nav li a {
    text-transform: uppercase;
    font-weight: 300;
    color: #ffffff;
}

.static_header a.navbar-brand {
    font-weight: 500;
    font-size: 32px;
    color: #ffffff !important;
}

.static_header .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #00b3ee !important;
}

.static_header .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    background-color: transparent;
}

.even p, .odd p {
    font-size: 18px;
    font-weight: 300;
}

.static_header .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

footer .bg-default {
    padding: 20px;
    border-top: 1px solid #f0f0f0;
}

.static_header .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    background-color: rgba(245, 245, 245, 0.23) !important;
    color: #000000 !important;

}

.sidebar_menu {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}

.buy_now_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.buy_now_container h1 {
    font-size: 46px;
    font-weight: 300;
    padding-bottom: 20px;
}

.modal-content {
    background-color: rgba(0, 179, 238, 0.8);
}

.m-r-20 {
    margin-right:30px;
}
.buy_now button, .buy_free button {
    /*width: 150px;*/
    font-size: 23px;
}

.instructions {
    font-size: 18px;
    font-weight: 300;
}

.buy_now_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.buy_now_input input {
    width: 300px;
    border-radius: 0 !important;
}

.buy_now_input {
    text-align: center;
}

.buy_now_input button {
    border-radius: 0 !important;
    margin-top: 10px;
}

.buy_now_btn {
    padding-top: 30px;
}

.close-animatedModal {
    cursor: pointer;
}

.close-animatedModal .fa.fa-close.fa-lg {
    position: fixed;
    right: 0;
    top: 0;
    font-size: 50px;
    z-index: 999999;
}
.display_type {
    display: flex;
    justify-content: center;
    align-items: center;
}

footer a {
    color: #FFFFFF;
}

.sidebar_menu div {
    list-style-type: none !important;
    padding-bottom: 30px;
    background-color: #00b3ee;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
}

.sidebar_menu div a {
    color: #ffffff;
}

.faq_search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.show_result_count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 300;
}

.log_heading {
    font-size: 19px;
    background-color: #00b3ee;
    margin-bottom: 10px;
    padding: 5px 20px;
}

.faq_search_container {
    padding: 20px 0 50px 0;
}

.faq_container:first-child {
    border-top: dotted 1px #00b3ee;
}

.faq_container {
    padding: 20px 0;
    border-bottom: dotted 1px #00b3ee;
}

#change_log {
    border-bottom: 1px solid #f1f1f1;
}

.faq_search_container {
    width: 75%;
    margin: 0 auto;
}

/*.faq_heading .fa-arrow-circle-o-down {*/
/*color: #00b3ee;*/
/*}*/

.faq_heading {
    font-size: 23px;
    font-weight: 300;
    cursor: pointer;
    color: #00b3ee;
}

.faq_content {
    font-size: 18px;
    font-weight: 300;
    padding-top: 20px;
    display: none;
}

.faq_content p {
    padding: 10px 0;
}

input#faq_search {
    width: 60%;
}

.log {
    font-size: 16px;
    text-indent: 20px;
    padding: 10px 0;
}

.change_log {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.plugin_feature {
    text-align: center;
    text-transform: uppercase;
    padding-top: 35px;
}

.static_header.full_size {
    /*height: 100vh;*/
}

.plugin_feature span {
    color: #00b3ee !important;
}

.feature_heading {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 300;
}

.odd, .even {
    border-bottom: 1px solid #f1f1f1;
    padding: 50px 0;
}

.plugin_name {
    text-align: center;
    padding: 40px 0;
    letter-spacing: 1px;
}


.plugin_name h1 {
    font-size: 70px !important;
    font-weight: 300 !important;
}

#home_page h1 {
    font-size: 50px !important;
    font-weight: 300 !important;
}
#home_page footer .bg-default{
    border-top: none !important;
}
.linkable {
    cursor: pointer !important;
    z-index: 9999;
}

#home_page footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
}

#home_page .linkable a {
    color: #FFFFFF;
}
.plugin_name h4 {
    font-size: 19px !important;
    font-weight: 300 !important;
}

.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper img {
    align-self: flex-start;
}

.price-block {
    /*max-width: 500px;*/
    margin: 0 auto 40px auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.price-block header {
    background: #2F4052;
    text-align: center;
    padding: 50px;
}

.price-block h2 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: .15em;
    text-transform: uppercase;
    color: #FFF;
}

.price-block .price {
    margin-bottom: 15px;
    color: #ffffff;
}

.price-block .btn {
    margin-bottom: 0;
    background-color: #00b3ee;
    border-color: #00b3ee;
}

.price-block .btn a {
    color: #ffffff;
}

.buy_now .btn, .buy_free .btn {
    background-color: #00b3ee;
    border-color: #00b3ee;
}

/*.pricing_container .tables-row{*/
/*display: flex;*/
/*}*/

.pricing_container {
    padding-top: 30px;
}

.price-block .features {
    /*background: #F5F7FA;*/
    padding: 25px 20px;
    /*border: 1px solid #E6E9ED;*/
    background-color: rgba(0, 150, 238, 0.32);
    border-top: none;
    border-bottom-width: 2px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-top: 0 !important;
}

.price-block .features li:first-child {
    border: none;
}

.price-block .features .heading_section div {
    padding: 15px 0;
    border-top: 1px solid #BCC7D1;
    list-style: none;
}

.heading_section {
    background-color: #F5F7FA;
    margin-bottom: 10px;
    padding: 20px;
    cursor: pointer;
}

.heading {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4682b4;
    border: none !important;
}

.price-block .price .currency {
    position: relative;
    top: 16px;
    font-size: 24px;
    vertical-align: top;
    margin-right: 5px;
}

.price-block .price .amount {
    font-size: 120px;
    font-weight: 900;
}

.price-block .price * {
    font-family: 'Lato', sans-serif;
    line-height: 100%;
}

.price-block .price .period {
    font-size: 16px;
    text-transform: uppercase;
}

.dropdown-menu > li > a {
    padding: 10px 20px !important;
    border-bottom: 1px solid #e7e6e8 !important;
}
.dropdown-menu > li:hover {
    background-color: #F0F0F0 !important;
}
.table_heading {
    padding: 10px;
    background-color: aliceblue;
}
.user_details_container {
    padding: 20px;
    margin: 5px 0;
    background-color: antiquewhite;
}
.transaction_details {
    padding-top: 10px;
}
.transaction {
    padding-bottom: 30px;
}


@media only screen and (min-width: 768px) {
    .cd-top {
        right: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .cd-top {
        height: 60px;
        width: 60px;
        right: 30px;
        bottom: 150px;
    }
}

@media (min-width: 320px) {
    .menu_name {
        display: none;
    }

    .sidebar_menu li {
        padding: 5px;
        font-size: 20px;
    }

    .faq_heading {
        font-size: 20px;
    }

}

@media (min-width: 481px) {
    .menu_name {
        display: none;
    }

    .sidebar_menu li {
        padding: 5px;
        font-size: 20px;
    }

    .faq_heading {
        font-size: 20px;
    }

}

@media (min-width: 641px) {
    .menu_name {
        display: none;
    }

    .sidebar_menu li {
        padding: 5px;
        font-size: 20px;
    }

    .faq_heading {
        font-size: 20px;
    }

}

@media (min-width: 961px) {
    .menu_name {
        display: inline-block;;
    }

}